<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>客户查找：</label>
        <el-input
            style="width:200px"
            size="mini"
            placeholder="请输入客户姓名/手机号"
            prefix-icon="el-icon-search"
            v-model="searchParams.keywords"
            clearable
            @change="getList('restPage')"
        >
        </el-input>
      </div>

      <el-button
          size="mini"
          type="primary"
          style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
          @click="getList('restPage')"
      >
        <span>查找</span>
      </el-button>
      <div style="margin-left: auto; display: flex; justify-content: space-around">

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-edit-outline"
                     style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleEditTableHead">编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-refresh"
                     style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleResetSearch">重置查找
          </el-button>
        </div>


        <!--对应第三方图标的button样式-->
        <div class="search-container-fn-input"
             v-if="operateList.indexOf('add') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-plus"
                     style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleAddRecord">创建档案
          </el-button>
        </div>

      </div>

    </div>
    <!--		<div class="search-container">
          <div class="search-container-fn-input">
            <label>建档日期：</label>
            <el-date-picker
              v-model="choiceDateCreateRecord"
              :default-time="['00:00:00', '23:59:59']"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd HH-mm-ss "
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleCreateRecordChangeDate">
            </el-date-picker>
          </div>
          <div class="search-container-fn-input">
            <label>到期日期：</label>
            <el-date-picker
              v-model="choiceDateExpire"
              :default-time="['00:00:00', '23:59:59']"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd HH-mm-ss "
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleExpireChangeDate">
            </el-date-picker>
          </div>
        </div>-->
    <!----------------表格---------------->
    <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{'text-align':'center'}"
        :data="tableData"
        height="625"
        style="width: 100%;z-index:0">
      <el-table-column
          type="index"
          align="center"
          width="50">
      </el-table-column>
      <template
          v-for="(column,index) in tableHead"
      >
        <el-table-column
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true "
            show-overflow-tooltip
        >
          <!--<template #header>
            <el-popover placement="bottom" title="" width="230" trigger="hover">
              <div slot="reference" class="search-header">
                <span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
                <i style="margin-left: 5px" class="search-icon el-icon-search"></i>
              </div>
              <el-input
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.buy_count"
                  clearable
                  @change="getList('restPage')"
                >
                </el-input>
            </el-popover>
          </template>-->
          <template slot-scope="scope">
            <a v-if="column.column_prop === 'score'"
               style="font-size: 12px; color: #2379fb"
               @click.prevent="handleScore(scope.row)">
              {{ scope.row[column.column_prop] }}
            </a>
            <a v-else style="font-size: 12px; color: #2379fb"
               @click.prevent="handleCustomerDetail(scope.row)">
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-else-if="column.field_type === 'imageBtn' && column.visible === true "
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
                 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
              <a>点击查看图片</a>
              <el-image
                  style="width: 0px; height: 0px"
                  :ref="scope.row[column.column_prop][0]"
                  :src="imageUrl"
                  :preview-src-list="scope.row[column.column_prop]"
                  :z-index="9999">
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
        >
          <template #header>
            <el-popover placement="bottom" title="" min-width="160" trigger="click"
                        v-if="searchTableHead.filter( item => item.name == column.column_prop).length > 0">
							<span slot="reference" class="search-header">
								<span
                    class="search-title"
                >{{
                    column.column_label_user_definition ? column.column_label_user_definition : column.column_label
                  }}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                   class="el-icon-search"
                   :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
                />
							</span>
              <el-input
                  v-if="'sign_count' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.buy_count"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.buy_count)"
              >
              </el-input>


              <el-select
                  v-else-if="'is_wx' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.is_wx"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.is_wx)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in optionsWX"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>

              <el-select
                  v-else-if="'visit_level' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.visit_level"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.visit_level)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in optionsVisitLevel"
                    :key="item.short_name"
                    :label="item.short_name"
                    :value="item.short_name">
                </el-option>
              </el-select>

              <el-select
                  v-else-if="'from' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.from_id"
                  placeholder="请输入客户来源"
                  size="small"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.from_id)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in customerFromOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>

              <el-date-picker
                  v-else-if="'created_at' == column.column_prop"
                  v-model="choiceDateCreateRecord"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleCreateRecordChangeDate()">
              </el-date-picker>

              <el-date-picker
                  v-else-if="'expired_at' == column.column_prop"
                  v-model="choiceDateExpire"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleExpireChangeDate">
              </el-date-picker>


              <el-date-picker
                  v-else-if="'end_visit_at' == column.column_prop"
                  v-model="choiceDateEndVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndVisitChangeDate">
              </el-date-picker>

              <el-date-picker
                  v-else-if="'end_order_at' == column.column_prop"
                  v-model="choiceDateEndOrder"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndOrderChangeDate">
              </el-date-picker>


              <el-date-picker
                  v-else-if="'next_visit_at' == column.column_prop"
                  v-model="choiceDateNextVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleNextVisitChangeDate">
              </el-date-picker>

              <el-select
                  v-else-if="'staff_name' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.staff_id"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.staff_id)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in staffOptions"
                    :key="item.id"
                    :label="item.staff_name"
                    :value="item.id">
                </el-option>
              </el-select>

            </el-popover>
            <span
                v-else>{{
                column.column_label_user_definition ? column.column_label_user_definition : column.column_label
              }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'buy_status'">{{
                scope.row[column.column_prop] === 1 ? '未购' : '已购'
              }}</span>
            <span v-else-if="column.column_prop === 'use_status'">
								{{ scope.row[column.column_prop] | useStatusFormat }}
							</span>
            <span v-else-if="column.column_prop === 'is_wx'">
								{{ scope.row[column.column_prop] == '0' ? '否' : '是' }}
							</span>
            <span v-else-if="column.column_prop === 'supervise_status'">
								{{ scope.row[column.column_prop] | formatSuperviseStatus }}
							</span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
                @pagination="getList"/>
    <!----------------抽屉弹出框-------------->
    <el-drawer
        title=""
        :visible.sync="drawerVisible"
        direction="rtl"
        v-loading="listLoading"
        :before-close="handleCloseDrawer"
        @closeDrawer="handleCloseDrawer"
        size="96%"
        :with-header="false"
    >
      <drawCustomerDetail
          @closeDrawer="handleCloseDrawer"
          @getList="getList"
          :info="info"
          v-if="drawerVisible"></drawCustomerDetail>
    </el-drawer>

    <!--传入宽度改变弹出框大小-->
    <dialogAddCustomer :isActiveDialog.sync="isActiveDialog"
                       @getList="getList"
                       v-if="isActiveDialog"
                       width="640"
    ></dialogAddCustomer>


    <!--编辑表头-->
    <editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
                        @_getAdminFieldIndex="_getAdminFieldIndex"
                        v-if="isActiveEditTableHead"
      :table_type="table_type"
    >

    </editTableHead>
    <el-dialog
        :visible.sync="scoreDialogVisible"
        :show-close="false"
        width="28vw"

    >
      <span>当前总分:{{ dimension_total }}</span>
      <div style="height:50vh">
        <div class="echart" id="mychart" :style="myChartStyle"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Pagination from '@/components/Pagination'
import drawCustomerDetail from './components/drawCustomerDetail';
import dialogAddCustomer from './components/dialogAddCustomer';
import {getAdminFieldIndex, getChancelFrom, getStaffList, getTaskList, getVisitLevel} from '@/api/ggkq';
import {mapGetters, mapState} from 'vuex';
import editTableHead from '@/components/editTableHead';

export default {
  // 当日签收
  name: "todaySign",
  components: {
    Pagination,
    drawCustomerDetail,
    dialogAddCustomer,
    editTableHead
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  async created() {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if (operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getAdminFieldIndex()
    this.getList()
    await this._getStaffList()
    await this._getCustomerFrom()
    await this.getVisitLevelList()
  },
  filters: {
    useStatusFormat: (status) => {
      let result = ''
      // 0 未用 1 在用 2 停用 3 预停
      switch (status) {
        case 0:
          result = '未用'
          break;
        case 1:
          result = '在用'
          break;
        case 2:
          result = '停用'
          break;
        case 3:
          result = '预停'
          break;
      }
      return result;
    }
  },
  data() {
    return {
      table_type:'todaySign',
      dimension_total: 0,//总分
      myChartStyle: {width: "100%", height: "100%"}, //图表样式
      scoreDialogVisible: false,
      searchTableHead: [
        {
          name: 'sign_count',
          isSearch: false
        },
        {
          name: 'is_wx',
          isSearch: false
        },
        {
          name: 'visit_level',
          isSearch: false
        },
        {
          name: 'created_at',
          isSearch: false
        },
        {
          name: 'expired_at',
          isSearch: false
        },
        {
          name: 'from',
          isSearch: false
        },
        {
          name: 'staff_name',
          isSearch: false
        },
        {
          name: 'end_visit_at',
          isSearch: false
        },
        {
          name: 'end_order_at',
          isSearch: false
        },
        {
          name: 'next_visit_at',
          isSearch: false
        },
      ],
      tableHeadSortable: ['created_at', 'expired_at', 'next_visit_at', 'end_visit_at', 'end_order_at'],
      isActiveEditTableHead: false,
      choiceDateCreateRecord: [],
      choiceDateExpire: [],
      choiceDateEndVisit: [],
      choiceDateEndOrder: [],
      choiceDateNextVisit: [],
      tableHead: [],
      searchParams: {
        type: 'today_sign',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      optionsWX: [
        {
          value: '0',
          label: '否'
        },
        {
          value: '1',
          label: '是'
        }],
      optionsVisitLevel: [],
      staffOptions: [],
      customerFromOptions: [],
      tableData: [],
      isActiveDialog: false,
      drawerVisible: false,
      info: {
        a: 1,
      },
      operateList: [],
    }
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'today_sign',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateCreateRecord = []
      this.choiceDateExpire = []
      this.choiceDateEndVisit = []
      this.choiceDateEndOrder = []
      this.choiceDateNextVisit = []
      this.getList('restPage')
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
    async _getCustomerFrom() {
      var params = {
        c_type: "from"
      }
      var result = await getChancelFrom(params)
      console.log(result)
      this.customerFromOptions = result.data
    },
    async _getAdminFieldIndex() {
      //   try {
      //     this.listLoading = true
      //     var params = {
      //       type: 'todaySign'
      //     }
      //     const res = await getAdminFieldIndex(params)
      this.tableHead = this.table_options.todaySign
      //     console.log(this.tableHead)
      //   } catch (err) {
      //     //在此处理错误
      //   } finally {
      //     this.listLoading = false
      //   }

    },
    getList(type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      console.log('getList触发')

      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      getTaskList(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    async getVisitLevelList() {
      var result = await getVisitLevel()
      console.log(result)
      this.optionsVisitLevel = result.data
    },
    async _getStaffList() {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },
    changeExamine(row) {  //选择状态 (启用/禁用)
      console.log(row.appraise_id)
      console.log(row.status)
      this.listLoading = true
      this.test().then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    },
    handleRefreshSearch() {

    },
    handleAddRecord() {
      this.isActiveDialog = true
    },
    handleCustomerDetail(row) {
      this.drawerVisible = true
      this.info = row
    },
    handleCloseDrawer() {
      this.getList() // 关闭draw, 刷新list数据
      this.drawerVisible = false
    },
    handleCreateRecordChangeDate() {
      this.searchParams.create_con_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateCreateRecord) {
        _tmp0 = this.choiceDateCreateRecord[0].substring(0, 10)
        _tmp1 = this.choiceDateCreateRecord[1].substring(0, 10)
        this.searchParams.create_con_at += _tmp0 + ","
        this.searchParams.create_con_at += _tmp1
      } else {

        this.searchParams.create_con_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'created_at') {
          if (this.searchParams.create_con_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })

      this.getList('restPage')
    },
    handleExpireChangeDate() {
      this.searchParams.expired_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateExpire) {
        _tmp0 = this.choiceDateExpire[0].substring(0, 10)
        _tmp1 = this.choiceDateExpire[1].substring(0, 10)
        this.searchParams.expired_at += _tmp0 + ","
        this.searchParams.expired_at += _tmp1
      } else {

        this.searchParams.expired_at = ""
      }

      this.searchTableHead.forEach(item => {
        if (item.name == 'expired_at') {
          if (this.searchParams.expired_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },

    handleEndVisitChangeDate() {
      this.searchParams.end_visit_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateEndVisit) {
        _tmp0 = this.choiceDateEndVisit[0].substring(0, 10)
        _tmp1 = this.choiceDateEndVisit[1].substring(0, 10)
        this.searchParams.end_visit_at += _tmp0 + ","
        this.searchParams.end_visit_at += _tmp1
      } else {

        this.searchParams.end_visit_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'end_visit_at') {
          if (this.searchParams.end_visit_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    //查看评分
    handleScore(row) {
      console.log(row)
      let dimen_config = row.dimen_config
      let dimension = row.dimension
      //计算dimension总数
      let dimension_total = 0
      dimension.forEach(item => {
        dimension_total += item
      })
      this.dimension_total = dimension_total
      console.log(dimen_config, dimension)
      this.scoreDialogVisible = true
      setTimeout(() => {
        this.initEcharts(dimen_config, dimension)
      }, 500);
    },
    initEcharts(list, score) {
      let option = {
        radar: {
          //图像位置
          center: ['50%', '50%'],
          indicator: list,
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            tooltip: {
              trigger: 'item'
            },
            type: 'radar',
            symbolSize: 0,
            data: [
              {
                value: score,
                name: '各项评分',
                areaStyle: {
                  color: 'rgba(35,98,251, 0.6)'
                },
                //线条样式
                lineStyle: {
                  color: '#2362FB'
                },
              },
            ]
          }
        ]
      };

      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);

      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    handleEndOrderChangeDate() {
      this.searchParams.end_order_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateEndOrder) {
        _tmp0 = this.choiceDateEndOrder[0].substring(0, 10)
        _tmp1 = this.choiceDateEndOrder[1].substring(0, 10)
        this.searchParams.end_order_at += _tmp0 + ","
        this.searchParams.end_order_at += _tmp1
      } else {

        this.searchParams.end_order_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'end_order_at') {
          if (this.searchParams.end_order_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },


    handleNextVisitChangeDate() {
      this.searchParams.next_visit_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateNextVisit) {
        _tmp0 = this.choiceDateNextVisit[0].substring(0, 10)
        _tmp1 = this.choiceDateNextVisit[1].substring(0, 10)
        this.searchParams.next_visit_at += _tmp0 + ","
        this.searchParams.next_visit_at += _tmp1
      } else {

        this.searchParams.next_visit_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'next_visit_at') {
          if (this.searchParams.next_visit_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },

  }
}
</script>

<style lang="scss" scoped>
.isSearch {
  color: #F56C6C;
}

.search-icon {
  color: #F56C6C;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core, .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
</style>
